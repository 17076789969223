import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Power of Attorney",
      content: (
        <>
          <p>
            A legal document that authorizes a person, your named agent, to make
            financial decisions on your behalf during your lifetime and becomes
            effective immediately or after certain conditions are met, such as
            incapacity.
          </p>
        </>
      ),
    },
    {
      title: "Advance Healthcare Directive",
      content: (
        <>
          <p>
            A legal document that authorizes a person, your named agent, to make
            personal and healthcare decisions on your behalf if you become
            incapacitated.
          </p>
        </>
      ),
    },
    {
      title: "Will",
      content: (
        <>
          <p>
            A written instrument that reflects a person’s wishes regarding the
            disposal of a person’s property at death. The term includes a
            codicil and any testamentary instrument that appoints an executor. A
            Will only becomes effective upon death and admitted to{" "}
            <a href="https://briertonjones.com/probate-attorney-san-diego/">
              Probate
            </a>
            . It must meet certain formalities to be considered valid.
          </p>
        </>
      ),
    },
    {
      title: "Trust",
      content: (
        <>
          <p>
            A written instrument that creates a legal entity that holds assets
            for beneficiaries and sets forth a trustee to manage such assets,
            the powers of the trustee, and the rights of the beneficiaries. A
            trust takes effect during the lifetime of the creator of a trust and
            is intended to avoid probate for any assets that are titled in the
            name of the trust or payable thereto. The existence of a trust must
            be revealed to beneficiaries and heirs when the terms become
            irrevocable. The terms usually become irrevocable when the creator
            of the trust dies.
          </p>
        </>
      ),
    },
    {
      title: "Testamentary Trust",
      content: (
        <>
          <p>A trust created by a will.</p>
        </>
      ),
    },
    {
      title: "Codicil",
      content: (
        <>
          <p>
            An amendment or addition to a will that may explain, modify, add to,
            subtract from, or revoke certain provisions in the will. A codicil
            must be executed with the same legal requirements as a will.
          </p>
        </>
      ),
    },
    {
      title: "Holographic Will",
      content: (
        <>
          <p>
            A type of will that is handwritten in the person’s own writing,
            dated and signed by the person making the will.
          </p>
        </>
      ),
    },
    {
      title: "Intestate",
      content: (
        <>
          <p>
            To die without a valid will, or without a valid distribution in a
            will.
          </p>
        </>
      ),
    },
    {
      title: "Personal Property",
      content: (
        <>
          <p>
            Anything owned by a person that can be moved, such as money,
            securities, jewelry, etc. Personal property does not include real
            property.
          </p>
        </>
      ),
    },

    {
      title: "Testate",
      content: (
        <>
          <p>To die leaving a valid will. </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Estate Planning Attorney San Diego | Brierton, Jones & Jones"
        description="When you need a top San Diego estate planning attorney, choose Brierton, Jones & Jones, LLP. We'll put our 30+ years of experience to work for you. Call today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  SECURE YOUR LEGACY
                </div>
                <h1>San Diego Estate Planning Attorney</h1>
                <p>
                  Everyone can benefit from an estate plan—young and old alike.
                  It’s not only about deciding how you want your assets managed
                  after you’re gone but also what to do when life takes an
                  unexpected turn.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/3.0 Estate Planning/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>Why Estate Planning Is So Important</h2>
                <p>
                  If there’s one thing that’s predictable in life, it’s that
                  life is unpredictable. Things can change instantly, and when
                  they do, you’ll want to be prepared to take care of the people
                  most important to you.
                </p>
                <p>
                  That’s where estate planning plays a key role. It’s a set of
                  legal tools and strategies that determine what happens to your
                  assets after you die or if you become incapacitated. It’s also
                  a way to empower people close to you to make important
                  decisions on your behalf if needed.
                </p>
                <p>
                  A clear, professionally developed estate plan ensures that
                  your wishes are carried out, helps avoid conflict between your
                  loved ones, and can minimize tax exposure. Estate conflicts
                  can often be quite emotional, and when they arise, having an{" "}
                  <a href="https://briertonjones.com/our-team/">
                    experienced attorney
                  </a>{" "}
                  to advocate on your behalf can make all the difference.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  When you start putting together your estate plan, you might
                  hear some unfamiliar terms. Here are the most important terms
                  for you to know:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={0} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="When you need a San Diego estate planning attorney to help you put everything in order, Brierton, Jones & Jones, LLP is here for you." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
